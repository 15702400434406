import logo from './assets/images/construction.png';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <h1>
        World Business Lenders Youth Foundation 
        </h1>
        <p2>
          Please Try Again Later
        </p2>
        
      </header>
    </div>
  );
}

export default App;
